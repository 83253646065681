import React, { useEffect } from "react";


import privacyPolicyBannerImg from '../../utils/assets/privacyPolicy/privacy-policy.png';
import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";
import PrivacyTerms from "../components/PrivacyTerms/PrivacyTerms";


const PrivacyPolicy = props => {
    
    useEffect(()=>{
        window.scroll(0,0);
    },[])

    const bannerSectionInfo =
     {
        cardTitle: "How we use the information we collect",
        cardBodyText: `At Pet assurance, your privacy is important to us. If you have any questions or concerns, please feel free to contact us for assistance.`,
        cardButtonText: "Contact Us",
        lengendBackground: privacyPolicyBannerImg
     }

    return (
        <React.Fragment>
        <WelcomeSection props={bannerSectionInfo}/>
        <NavegationInfo page="Privacy policy"/>
        <PrivacyTerms/>

        </React.Fragment>
    );
};

export default PrivacyPolicy;