import React, { useEffect } from "react";


import WelcomeSection from "../../shared/components/WelcomeSection/WelcomeSection";
import SectionDivider from "../components/SectionDivider/SectionDivider";
import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import SubscriptionPlan from "../components/SubscriptionPlan/SubscriptionPlan";
import ProductDescription from "../../shared/components/ProductDescription/ProductDescription";

import catAndDogBannerImg from '../../utils/assets/home/dog-and-a-cat-on-a-sofa-banner.png';
import vetVideoCall from '../../utils/assets/home/video-call-with-a-vet.png';
import carryTheCatBannerImg from '../../utils/assets/home/carry-the-cat.png';
import cuddleDog from '../../utils/assets/price/cuddle-a-dog.png';

import asistence247Icon from '../../utils/assets/home/icons/assistance-icon.png';
import canineEducationIcon from '../../utils/assets/home/icons/education-icon.png';
import petTrainingIcon from '../../utils/assets/home/icons/training-icon.png';
import discountedPetMedicationIcon from '../../utils/assets/home/icons/meds-icon.png';
import boradCertifiedIcon from '../../utils/assets/home/icons/wellness-icon.png';
import petWellnessIcon from '../../utils/assets/home/icons/vets-icon.png'


const Home = props => {

    useEffect(()=>{
        window.scroll(0,0);
    },[])

     const bannerSectionInfo = [
         {
             cardTitle : "Access to a vet at any time",
             cardButtonText : "start now",
             cardBannerPosition: 1,
             lengendBackground : catAndDogBannerImg
         },
         {
            cardTitle : "Your pet's well-being matters",
            cardBannerPosition: 2,
            cardButtonText : "Join us",
            lengendBackground : carryTheCatBannerImg
        } 

     ]

     const dividerInfo = {
            sectionName : "BENEFITS",
            icon1 : asistence247Icon,
            description1 : "Specialty Referral",
            icon2: canineEducationIcon,
            description2 : "24 / 7 / 365 assistance",
            icon3 : petTrainingIcon,
            description3 : "Free medical counseling",
            icon4 : discountedPetMedicationIcon,
            description4 : "AI-powered mobile app",
            icon5 : boradCertifiedIcon,
            description5:"medical prescriptions",
            icon6 : petWellnessIcon,
            description6 : "virtual consultation"

        }

     const productDescription = {
        title : "How does it work?", 
        subtitle : "Pet assurance is a monthly pet care plan offering veterinary telemedicine services to answer questions and ease concerns.",
        paragraphs : ['The plan provides virtual medical care 24 hours a day, 7 days a week, 365 days a year, saving you money on veterinary visits and giving you great discounts on everything related to your pet.',
                    'Get the fastest access to veterinary care for your pet in just 4 simple steps:'
                               
    ],
        instructions : [
            'All you need to do is to go to your health portal on the web and enter your member ID and password.',
            'Fill out your member profile and add any pets you have.',
            'Select the vet you want to start the video call with.',
            'Start saving with discounts of up to  70%'
        ],
        img : vetVideoCall

     }

     const subscriptionInformation = [
        {       
            subscriptionType: "MONTH",
            subscriptionPrice: "36.72",
            subscriptionTrialInfo: "Try now for 30 days",
            subscriptionTrialPrice : "3.29",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy"
        }, 
        {
            img: cuddleDog,
            title : "Are you ready to rest easy knowing your pet has a vet available 24/7?",
        }
    ]

    return(            
        <React.Fragment>
            <WelcomeSection props={bannerSectionInfo[0]}/>
            <SectionDivider props={dividerInfo}/> 
            <ProductDescription props={productDescription}/>
            <WelcomeSection props={bannerSectionInfo[1]}/>
            <SectionInfo title={"Enjoy your membership now"}/>
            <SubscriptionPlan props={subscriptionInformation}/> 
        </React.Fragment>
    );
};

export default Home;