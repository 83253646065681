export const PROJECT = {
    DOMAIN : 'www.petsandsavings.com',
    MERCHAN_ID : 53,
    AMOUNT : '',
    BUILD_ORDER : '1',
    CAPTURE_DELAY : '0',
    PRODUCT_COUNT : '1',
    PRODUCT_ID : '9',
    PRODUCT_SKU_1 : 'petSavingsProduct1',
    PRODUCT_QTY_1 : '1',
    BINCHECK : '1',
    SKIP : '1'


}