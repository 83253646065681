import React from 'react';
import { NavLink } from 'react-router-dom';

import './NavegationLinks.css';
import CallUsButton from '../../../UIElements/CallUsButton';

const NavLinks = props => {



  const handleHomeClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
    <ul className="nav-links" >
      <li>
        <NavLink to="/"  onClick={handleHomeClick} exact>HOME</NavLink>
      </li>

      <li>
        <NavLink to="/about-us" onClick={handleHomeClick}>ABOUT US</NavLink>
      </li>

      <li>
        <NavLink to="/price" onClick={handleHomeClick}>PRICE</NavLink>
      </li>

      <li>
        <NavLink to="/contact-us" onClick={handleHomeClick}>CONTACT</NavLink>
      </li>

      <li>
        <NavLink to="/cancel-service" onClick={handleHomeClick}>CANCEL</NavLink>
      </li>

      <li>
        {/* <NavLink to="/login" onClick={handleHomeClick}>LOGIN</NavLink> */}
        <a href='https://portal.golexi.com' target='_blank'>LOGIN</a>
      </li>

    
       <li className='NavLinks__call-us-button-link' >
        <CallUsButton
        phonenumber="CALL NOW"
        />
      </li> 
    </ul>

    </React.Fragment>
  );
};

export default NavLinks;
