import React, { useState } from 'react';
import { Link } from 'react-router-dom';



import './Header.css';

import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from '../UIElements/Backdrop';
import NavegationLinks from './components/NavegationLinks/NavegationLinks';
import Header from './components/Header/Header';

//assets
import petLoversIcon from '../../../utils/assets/logo/pet-lovers-icon.png';
import facebookIcon from '../../../utils/assets/header/facebook-icon.png';
import instagramIcon from '../../../utils/assets/header/instagram-icon.png';

const MainNavigation = props => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const openDrawerHandler = () => {
    setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  return (
    <React.Fragment>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
      <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
        <nav className="main-navigation__drawer-nav">
          <NavegationLinks />
        </nav>
      </SideDrawer>

      <Header>
        <button
          className="main-navigation__menu-btn"
          onClick={openDrawerHandler}
        >
          <span />
          <span />
          <span />
        </button>
        <h1 className="main-navigation__title">
          <Link to="/"><img src={petLoversIcon} alt='pet-lovers-icon' className='Navigation__logo'></img></Link>
        </h1>
        <nav className="main-navigation__header-nav">
          <NavegationLinks />
        </nav>
        <div></div>
      </Header>
    </React.Fragment>
  );
};

export default MainNavigation;
