import React from "react";

import './CallUsButton.css';

const CallUsButton = props => {
    const cellphone = "8554131910";
    const handleButtonClick = () => {
        window.location.href = `tel:${cellphone}`;
    };
    return (

        <button className="call-us-button" onClick={handleButtonClick} style={{ fontSize: '1.1rem', color: '#E66931', fontWeight: '700' }}>
            {props.phonenumber}
        </button>

    );
};

export default CallUsButton;