
import { PROJECT } from "./helpers/constants/checkoutData";
import { API } from "./helpers/constants/apiUrl";
import {encryptData} from './helpers/encryption/encryptionData';

const submitPayment = (params) =>{


const ExpMonth = params.expDate.split('-')[0];
const ExpYear = params.expDate.split('-')[1];
const ExpYearR= 20 + ExpYear;

const formData = {
  domain: PROJECT.DOMAIN,
  APIKey: process.env.REACT_APP_PAYMENT,
  MerchantID: PROJECT.MERCHAN_ID,
  buildOrder: PROJECT.BUILD_ORDER,
  capture_delay: PROJECT.CAPTURA_DELAY,
  card_number: params.cardNumber,
  card_expm: ExpMonth,
  card_expy: ExpYearR,
  card_cvv: params.fcvv,
  CustomerFirstName: params.firstName,
  CustomerLastName: params.lastName,
  BillingStreetAddress: params.address,
  BillingApt: params.addressApt,
  BillingCity: params.city,
  BillingState: params.state,
  BillingZipCode: params.zipCode,
  Email: params.email,
  BillingHomePhone: params.phoneNumber,
  amount: params.productPrice,
  ProductCount: PROJECT.PRODUCT_COUNT,
  productid_1: PROJECT.PRODUCT_ID,
  productsku_1: PROJECT.PRODUCT_KU_1,
  productqty_1: PROJECT.PRODUCT_QT_Y_1,
  bincheck: PROJECT.BINCHECK,
  skip: PROJECT.SKIP
}

const { encrypt , iv } = encryptData(formData);

const requestOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify( { encryptedData : encrypt, iv : iv } ), 
};

return fetch(API.CHECKOUT_FORM, requestOptions)
  .then(response => response.text()) 
  .then(data =>{
    return data;
  })
  .catch( error =>{
      throw error;
  })
}

export default submitPayment;